import { Suspense } from "react";
import "./App.css";
import logo from "./assets/logo.png";
import { Box, CircularProgress } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

function App() {
  const Bamenda_link = process.env.REACT_APP_BAMENDA_URL;
  const kumba_link = process.env.REACT_APP_KUMBA_URL;
  const Molyko_link = process.env.REACT_APP_MOLYKO_URL;
  const Muyuka_link = process.env.REACT_APP_MUYUKA_URL;
  const Payment_link = process.env.REACT_APP_PAYMENT_URL;
  return (
    <>
      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backgroundColor: "#f7f7f7 !important",
            }}
          >
            <CircularProgress sx={{ color: "black" }} />
          </Box>
        }
      >
        <div className="container">
          <nav className="top-container">
            <img src={logo} alt="Fgm Logo" className="logo" />
            <ul>
              <li>Home</li>
              <li>About </li>
              <li>
                <button
                  className="btn"
                  style={{
                    fontSize: "17px",
                    fontFamily: "inherit",
                    color: "white",
                    backgroundColor: "black",
                  }}
                  onClick={() => window.open(Payment_link, "_blank")}
                >
                  Pay Fees
                </button>
              </li>
              <div className="dropdown">
                <button className="dropbtn">
                  FGM SCHOOLS
                  <span
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <ArrowDropDown sx={{ verticalAlign: "bottom" }} />
                  </span>
                </button>
                <div className="dropdown-content">
                  <a href={Bamenda_link}>FGHS Bamenda</a>
                  <a href={kumba_link}>FUGOSEC Kumba</a>
                  <a href={Molyko_link}>FUGOSEC MOLYKO</a>
                  <a href={Muyuka_link}>FUGOHIS MUYUKA</a>
                </div>
              </div>
            </ul>
          </nav>
          <div className="background ">
            <div className="main-text">
              <h1>Full Gospel Schools assuring a better world</h1>
              <p>
                Full gospel schools provides a cutting-edge curriculum, designed
                to empower our students to become tomorrow's leaders. We are
                committed to providing a safe and secure learning environment
                that fosters critical thinking, creativity, and innovation.
              </p>
              <div className="btn-container">
                <div className="btn-icon">
                  <a href={Bamenda_link} className="sbtn">
                    FGHS Bamenda
                  </a>
                </div>
                <div className="btn-icon">
                  <a href={kumba_link} className="sbtn">
                    FUGOSEC Kumba
                  </a>
                </div>
              </div>
              {/* <button className="btn">Learn More</button> */}
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default App;
